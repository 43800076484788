.checkout-content {
	@include mx-auto;
	@include group;
	color: #fff;
	max-width: 1800px;
	padding: 0 $space4;
	@include s {
		padding: $space2;
	};

}

.current-cart {
	float: left;
	width: 400px;
	@media (max-width: 450px) {
		width: 100%;
	}
	>.title:first-child {
		@extend %font-rounded;
		margin-bottom: $space2;
	}
	.cart-item {
		width: 100%;
		.price {
			min-width: 39px;
		}
	}
	.pickup-time {
		input {
			width: 40px;
			text-align: center;
			&:first-child {
				margin-right: -11px;
				.edge &, .ie11 & {
					padding-right: 0;
				}
			}
			&:last-child {
				margin-left: -15px;
				.ie11 & {
					margin-right: 0;
				}
				@include firefox {
					margin-left: -3px;
				};
			}
		}
	}
}

.billing {
	float: right;
	max-width: 900px;
	width: 60%;
	width: calc(100% - 400px - #{$space3});
	label {
		display: block;
		margin-bottom: $space2;
		* {
			font-size: $h5;
		}
		.text {
			display: block;
			@media (min-width: 1251px) {
				display: inline-block;
				width: 28%;
			}
		}
		&[for=accept] {
			.text {
				width: auto;
			}
			a {
				text-decoration: underline;
				&:hover {
					text-decoration: none;
				}
			}
		}

	}
	>.title {
		@extend %font-rounded;
		margin-bottom: $space2;
	}
	@media (max-width: 930px) {
		clear: both;
		float: none;
		width: 100%;
	}
}

#billing-form {
	@include group;
	.form-fields {
		@include grid(6, true, $space4);
		border: none;
		float: left;
		outline: none;
		@media (max-width: 640px) {
			@include grid(6, true, $space2);
		}
		@media (max-width: 420px) {
			@include grid(12);
		}
	}
}

.billing-input {
	border: none;
	border-radius: 0;
	color: $black;
	outline: none;
	padding: $space1 * 0.7;
	width: 70%;
	&.expiry-field {
		width: auto;
		max-width: 75px;
	}
	&.ng-dirty.ng-invalid {
		box-shadow: 0 0 0 1px red;
		outline: red;
	}
	@media (max-width: 1250px) {
		width: 100%;
	}
}
#cvv {
	width: 80px;
}

#accept {
	border: none;
	outline: none;

}

#checkout-btn {
	background-color: transparent;
	border: 3px solid #fff;
	cursor: pointer;
	clear: both;
	font-weight: bold;
	letter-spacing: 1px;
	margin-top: $space3;
	outline: none;
	padding: ($space1 * 0.5) ($space1 * 0.8);
	text-transform: uppercase;
	width: grid-width(6, true, $space4);
	@media (max-width: 640px) {
		width: auto;
	}
}

.validation-message {
	color: red;
}

#account-fields {
	@include cleanInput;
	display: block;
	float: none;
	width: 100%;
	.text {
		width: 140px;
	}
	input {
		color: $black;
		width: auto;
	}
}
