.page-cart, .page-checkout {
	.entry-title {
		color: #fff;
	}
}

.cart-btn {
	border: 3px solid #fff;
	display: block;
	margin-top: $space2;
	padding: ($space1 / 2) $space1;
}

.cart-content {
	@include mx-auto;
	color: #fff;
	font-weight: 500;
	max-width: 400px;

	@include s {
		@include px($space2);
	};
}

.cart-items {
	@include anim(opacity);
	border: 3px solid #fff;
	font-weight: 500;
	margin-bottom: $space2;
	padding: $space1;
	position: relative;
	width: 100%;
	> * {
		display: table;
		> * {
			display: table-cell;
		}
	}
	.updating-cart & {
		opacity: 0.3;
	}
}

.cart-item {
	margin-bottom: $space1;
	&:last-child {
		margin-bottom: 0;
	}
	width: 122%;
	&.is-extra {
		width: 103%;
	}
	.quantity {
		width: 10%;
		min-width: 50px;
	}
	.price {
		width: 10%;
		min-width: 60px;
	}
	.remove {
		@include anim(opacity);
		cursor: pointer;
		display: block;
		margin-left: $space2;
		opacity: 0;
		pointer-events: none;
	}
	&:hover {
		.remove {
			opacity: 1;
			pointer-events: all;
		}
	}
}

.cart-title {
	width: 80%;
	.is-extra & {
		padding-left: 3px;
	}
	&.extra-title {
		font-weight: normal;
		padding-left: 15px;
		width: 60px;
		white-space: nowrap;
	}
}

.total-title {
	@extend %font-rounded;
	text-transform: uppercase;
}

.cart-item, .title.cart-title {

	font-size: $h4;
	font-weight: 500;
	text-transform: lowercase;
}

.cart-empty {
	text-align: center;
	width: 100%;
}

.cart-meta {
	display: table;
	margin-bottom: $space4;
	width: 100%;
	> span {
		display: table-cell;
	}
	.item-count {
		width: 20%;
	}
	@media (max-width: 400px) {
		font-size: $h5;
	}
}

.pickup-time {
	text-align: right;
	input {
		@extend %font-rounded;
		@include firefoxHideNumber;
		display: inline-block;
		background-color: transparent;
		border-radius: 0;
		border: none;
		// padding: 5px 3px;
		outline: none;
		position: relative;
		text-align: left;
		width: 45px;
		&:first-child {
			margin-right: -15px;
			.edge &, .ie11 & {
				padding-right: 14px;
			}
			text-align: right;
			z-index: 2;
		}
		&:last-child {
			margin-left: -2px;
			.ie11 & {
				margin-right: -10px;
			}
		}
		@media (max-width: $m) {
			text-align: center;
		};
	}
	.hours {
		border-right: none;
		padding-right: 0;
	}
	.minutes {
		border-left: none;
	}
	.time {
		display: inline-block;
		border: 3px solid #fff;
		padding: 5px 0;
		// width: 0px;
	}
	.page-cart & {
		@supports (display: inline-flex) {
			.time {
				display: inline-flex;
				width: 80px;
			}
			input {
				width: 47px;
				&:first-child {
					margin-right: -13px;
				}
				&:last-child {
					margin-left: 1px;

				}
			}
		}
		@include firefox {
			input {
				&:first-child {
					text-align: left;
					margin-right: -10px;
					padding-left: 8px;
					width: 45px;
				}
				&:last-child {
					margin-left: 4px;
				}
			}
		};
	}
}

.shop-btn {
	@extend %font-rounded;
}

.total-cost {
	@extend %font-rounded;
	@media (max-width: 400px) {
		font-size: $h4;
	}
}
