.click-collect {
	z-index: 2;
}

.shop-closed {
	@include anim(transform);
	background-color: $black;
	color: #fff;
	font-size: $h6;
	max-width: 147px;
	padding: $space2;
	transform: translateX(100%);
	z-index: 1;
	&.show {
		transform: translateX(0);
	}

	@include lmax {
		display: none;
		width: 160px;
		position: absolute;
		transform: translateX(0);
		&.show {
			display: block;
		}
	};
}

.closed-item {
	margin-bottom: $space2;
}
