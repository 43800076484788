.account-login {
	.form-content {
		@include m {
			width: 400px;
		};
		label {
			margin-right: $space2;
			min-width: 100px;
		}
	}
}
