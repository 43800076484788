.farmers-title {
	position: relative;
	z-index: 2;
}
.farmers-slider-wrapper {
	.arrow {
		@media (max-width: #{$l - 1}) {
			position: fixed;
			bottom: 0;
			top: auto;
			width: 40px;
			height: 40px;
			margin-left: 10px;
			margin-right: 10px;
			background: white;
		}
		path {
			fill: inherit;
			transition: fill .3s ease-out;
		}
	}
}
.farmers-slider {
	padding-bottom: 700px;
	margin-top: -50px;
}
.farmers-slide {
	background: white;
	display: flex;
	flex-direction: column;
		align-items: center;
	// @media (min-width: $l) {
	// 	justify-content: center;
	// }
}
@mixin farmers-box {
	border: 4px solid;
	img {
		width: 100%;
		height: 100%;
	}
}
.farmers-icon {
	@include farmers-box;
	width: 120px;
	height: 120px;
	padding: 20px;
	position: relative;
	margin-left: 60vw;
	margin-bottom: -5px;
	.farmers-plus {
		position: absolute;
		left: -24px;
		bottom: -25px;
		z-index: 2;
		background: linear-gradient(to bottom, white 0%, white 55%, transparent 55%, transparent);
	}
	img {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
	@media (min-width: $m) {
		margin-left: 500px;
	}
	@media (max-width: #{$m - 1}) {
		width: 100px;
		height: 100px;
		padding: 10px;
		margin-top: 3rem;
	}
}
.farmers-image {
	@include farmers-box;
	width: 500px;
	max-width: 80%;
	height: 300px;
	max-height: 50vw;
	margin-top: 1px;
	overflow: hidden;
	img {
		display: block;
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	@media (max-width: #{$l - 1}) {
		margin-right: 10%;
	}
}

.farmers-text {
	max-width: 300px;
	font-size: .8rem;
	font-weight: 500;
	padding: 1rem;
	position: relative;
	.farmers-plus {
		display: none;
	}
	@media (min-width: $l) {
		@include farmers-box;
		margin-top: -80px;
		margin-left: -792.5px;
		.farmers-plus {
			display: block;
			position: absolute;
			right: -26px;
			top: -24px;
		}
	}
}
.farmers-plus {
	background: white;
	width: 44px;
	height: 44px;
	padding: 11px;
	position: relative;
	border-radius: 40%;
	svg {
		width: 22px;
		height: 22px;
		position: relative;
		z-index: 1;
	}
	path {
		stroke: currentColor;
	}
}
