.archive-small-grid.drinks-grid {

	.product-overlay {
		height: 110%;
		width: 102%;
		.marker {
			margin-top: 2px;
			padding: 0;
			.product:hover & {
				@include normalize;

			}
		}
		.tick, .add {
			width: 20px;
			height: 20px;
		}
		.load {
			@include flexCenter;
			height: 30px;
		}
	}
	.add-text {
		padding-right: $space2;
		text-align: right;
	}
}
