.page-catering, .page-my-account {
	.entry {
		margin-top: -$subMenuHeight;
	}
}

.delivery-image {
	width: 100%;
}
.box-header {
	color: $black;
	margin: 4rem 2rem;
	.entry-title {
		padding: 0;
	}
	@media (max-width: #{$m - 1}) {
		// color: #fff;
		position: absolute;
		top: 0;
	}
}
.box-layout {
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
	> *:nth-child(2) {
		flex: 1;
	}
	@media (min-width: $m) {
		display: flex;
	}
}
.box-grid {
	display: flex;
	flex-direction: column;
	align-items: center;
	@media (min-width: $m) {
		flex-direction: row;
		align-items: stretch;
		justify-content: flex-end;
	}
}
.link-box {
	background: #008778;
	color: white;
	width: 300px;
	max-width: calc(100% - 2rem);
	height: 250px;
	max-height: 80vw;
	min-height: 180px;
	margin: .5rem;
	padding: 1rem 1.5rem;
	display: flex;
	flex-direction: column;
	transition: opacity .1s ease-out;
	&:not(:hover) {
		opacity: .7;
	}
}
.box-inner-title {
	flex: 1;
	text-transform: lowercase;
	font-size: 1.5rem;
	font-weight: 500;
	@media (min-width: $m) {
		font-size: 2rem;
	}
}
.box-inner-btn {
	@include cleanInput;
	background-color: transparent;
	border: 2px solid;
	padding: .3em .5em;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: .1em;
	font-size: .75rem;
	width: 100%;

	&:not(:first-child) {
		margin-top: $space1;
	}
}
