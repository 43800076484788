.entry {
	.drinks-categories-wrapper {
		max-width: 1000px;
	}
}

.category-grid {
	background-color: #fff;
	margin-bottom: $space3;
	.cat-title {
		@include border;
		border-color: inherit;
	}
}

.drinks-product {
	border-top: none;
	margin-bottom: 0;

}

.drinks-title {
	color: $black;
}
