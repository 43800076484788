
$widths: (10, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 75, 80, 90, 100);

@mixin fill { width: 100%; height: 100% }
@mixin w33 { width: 33.333%; width: calc(100% / 3) }
@mixin w66 { width: 66.666%; width: calc(100% / 3 * 2) }

%fill {
	width: 100%;
	height: 100%;
}
%w33 { @include w33 }
%w66 { @include w66 }
@each $n in $widths {
	%w#{$n} { width: #{$n} * 1% }
	%h#{$n} { height: #{$n} * 1% }
}

@include all(w33) { @include w33 };
@include all(w66) { @include w66 };
@each $n in $widths {
	@include all(w#{$n}) { width: #{$n} * 1% }
	@include all(h#{$n}) { height: #{$n} * 1% }
}

@include all(fill) { width: 100%; height: 100% };
