
*, *:after, *:before {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
	font-family: inherit;
	font-size: inherit;
	text-align: inherit;
	line-height: inherit;
	color: inherit;
}
html {
	box-sizing: border-box;
	color: lighten(#222, 10%);
	line-height: 1.6;
}

a {
	text-decoration: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
pre, code, kbd {
	font-family: monospace;
}
pre {
	overflow: auto;
	padding: .5rem;
}
svg {
	height: auto;
}

main, article, aside, details, figcaption, figure, footer,
header, hgroup, iframe, main, menu, nav, section {
	display: block
}
img, table, svg, iframe { max-width: 100%; border: 0 }
tr { border-bottom: 1px solid rgba(128, 128, 128, .25) }
th, td { padding: .5rem }
ul, ol { padding-left: 2rem }
::-moz-focus-inner { padding: 0; border: 0 }

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
	// background-color: lighten(#d4d4d4, 10%);
}

::-webkit-scrollbar-thumb {
    background-color: #d4d4d4;
}
