.slider-wrapper {
	position: relative;
}

.header-slider {
	background: black;
	margin-bottom: $space4;
	padding-bottom: calc(100vh - (#{$headerHeight} + #{$footerHeight}));
	@include lmax {
		padding-bottom: calc(100vh - #{$headerHeightMobile});
	};
	@media (max-aspect-ratio: 1/1) {
		padding-bottom: 80%;
	}
	.slide {
		position: relative;
	}
	img {
		@include fill;
		object-fit: cover;
		position: relative;
		z-index: 1;
	}
	.slide-meta {
		max-width: 650px;
		position: absolute;
		width: 100%;
		z-index: 2;
		&.top {
			top: $spacing;
			&.left {
				left: $spacing;
			}
			&.center {
				left: 50%;
				transform: translateX(-50%);
			}
			&.right {
				right: $spacing;
			}
		}
		&.right.center {
			right: $spacing;
			top: 50%;
			transform: translateY(-50%);
		}
		&.bottom {
			bottom: $spacing;
			&.right {
				right: $spacing;
			}
			&.left {
				left: $spacing;
			}
			&.center {
				left: 50%;
				transform: translateX(-50%);
			}
		}
		&.left.center {
			left: $spacing;
			top: 50%;
			transform: translateY(-50%);
		}
		@include s {
			&.bottom.left, &.top.left, &.bottom.right, &.top.right {
				left: auto;
				right: auto;
			}
			padding: $space3;
		};
	}
}

.slide-title {
	@extend %font-rounded;
	@include largeTitle;
	margin-bottom: $space1 * 0.5;
	text-transform: uppercase;
	@include m {
		white-space: nowrap;
	};
}

.slide-img {
	&.top-right {
		object-position: top right;
	}
}

.text {
	&-left {
		text-align: left;
	}
	&-right {
		text-align: right;
	}
	&-center {
		text-align: center;
	}
}

.slide-text {
	font-size: $h2 * 1.2;
	letter-spacing: 0.5px;
	@include s {
		font-size: $h6;
	};
}

.arrow {
	cursor: pointer;
	position: absolute;
	width: 45px;
	z-index: 3;
	@include centerY;
	&.next {
		right: $space2;
		@include lmax {
			right: 0;
		};
	}
	&.prev {
		left: $space2;
		svg {
			transform: rotate(180deg);
		}
		@include lmax {
			left: 0;
		};
	}
	@include lmax {
		padding: 5px;
		width: 30px;
	};
}
