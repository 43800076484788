
@mixin debug { outline: 1px solid #f08 }
@mixin noSelect { @include prefix(user-select, none) }
$easeOut : cubic-bezier(.25,.8,.25,1);
@mixin animate { transition: .4s $easeOut; }
@mixin hoverLight { &:hover { @include filter(brightness(120%)) } }
@mixin clickable { cursor: pointer }
@mixin noBullets { list-style: none; padding-left: initial }

@mixin transparent { opacity: 0 }
@mixin opaque { opacity: 1 }
@mixin crop { overflow: hidden }
@mixin cropX { overflow-x: hidden }
@mixin cover { object-fit: cover }
@mixin contain { object-fit: contain }
@mixin bgCover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
@mixin bgContain {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;
}

@mixin bgBlack {
	background-color: #222;
}

@mixin bgWhite {
	background-color: #fff;
}

@mixin cleanInput {
	outline: 0;
	border: none;
	-moz-appearance: textfield;
	-webkit-appearance: none;
	&[type=checkbox], &[type=radio] {
		opacity: 0;
	}
}

@mixin firefox {
	@-moz-document url-prefix() {
		@content;
	}
}

@mixin firefoxHideNumber {
	-moz-appearance: textfield;
	@-moz-document url-prefix() {
		padding-right: 15px;
	}
	&:focus {
		-moz-appearance: none;
		@-moz-document url-prefix() {
			padding-right: 0;
		}
	}
}

%debug { @include debug }
%noSelect { @include noSelect }
%animate { @include animate }
%hoverLight { @include hoverLight }
%clickable { @include clickable }
%noBullets { @include noBullets }
%transparent { @include transparent }
%opaque { @include opaque }
%crop { @include crop }
%cropX { @include cropX }
%cover { @include cover }
%contain { @include contain }
%bgCover { @include bgCover }
%bgContain { @include bgContain }
%bgBlack { @include bgBlack;}
%bgWhite { @include bgWhite;}
%cleanInput { @include cleanInput }

@include all(debug) { @include debug }
@include all(transparent) { @include transparent }
@include all(opaque) { @include opaque }
@include all(crop) { @include crop }
@include all(cropX) { @include cropX }
@include all(cover) { @include cover }
@include all(contain) { @include contain }
@include all(bgCover) { @include bgCover }
@include all(bgContain) { @include bgContain }
@include all(bgBlack) { @include bgBlack }
@include all(bgWhite) { @include bgWhite }
