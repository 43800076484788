.page-click-collect {
	.entry-header {
		color: #fff;
		position: absolute;
		top: 0;
	}
	.entry-bg {
		margin-top: -$subMenuHeight;
	}
}

.shop-categories {
	@include group;
	@include mx-auto;
	max-width: 900px;
}

.shop-category {
	float: left;
	padding: $space3;
	width: 20%;
	@include s {
		@include grid(6, false);
		padding: $space2;
	};
}

.cat-vector-wrapper {
	@include anim();
	@include flexCenter;
	margin-bottom: $space1;
	padding: $space2;
	text-align: center;
	* {
		display: inline-block;
		height: 80px;
	}
	path {
		@include anim();
	}
	&:hover {
		background-color: #F2EEE4 !important;
		path {
			fill: inherit;
		}
	}
}

.cat-verb-title {
	text-transform: uppercase;
	font-size: $h3;
	margin-bottom: ($space1 * 0.3);
	@include s {
		font-size: $h4;
	};
}

.cat-sub-title {
	font-size: $h5;
	font-weight: normal;
}

.click-content {
	font-size: $h3;
	margin-top: $space2;
	text-transform: lowercase;
	@include m {
		text-align: center;
	};
	@include s {
		@include px($space2);
		font-size: $h4;

	};
}
