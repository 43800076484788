.category-create-your-own {
	.entry-wrapper {
		@media (min-width: 1061px) {
			height: 800px;
			height: 100vh;
			overflow: hidden;
		}
	}
	.entry {
		height: 100%;
		position: relative;
	}
}

.shop-create-wrapper {
	position: relative;
	@media (max-width: 1060px) {
		padding: $space2;
	}
	.shop-categories-wrapper {
		@include grid(7, true, $space4);
		height: 100%;
		overflow-y: auto;
		width: calc(100% - 400px - #{$space4});
		@media (max-width: 1060px) {
			@include grid(12);
			height: auto;
			overflow-y: visible;
		}
	}
}

.shop-sidebar {
	@include grid(4, true, $space4);

	margin-right: 0;
}

.shop-create-category {
	@include border;
	@include group;
	margin-bottom: $space1;
	.create-title {
		border-bottom: none;
	}
	&.section-open {
		.create-title {
			border-bottom: 3px solid;
		}
	}

}

.create-title {
	@include group;
	font-size: $h4;
	@include s {
		font-size: $h6;
	};
}

.shop-create-header {
	@include noSelect;
	cursor: pointer;
}

.hiding-wrapper {
	// @include anim(height, 0.55s);
	display: none;
	// height: 0;

	overflow-y: auto;
	width: 100%;
	@include m {
		max-height: 380px;
		// max-height: 40vh;
		overflow-x: hidden;
	};
}

.shop-create-products {
	@include group;
	@include flex;
	flex-wrap: wrap;
	.section-open & {

	}
}

.shop-create-product {
	background-color: $headerColor;
	float: left;
	margin-bottom: 5px;
	margin-right: 5px;
	position: relative;
	z-index: 1;
	.product-info {
		margin-top: -40px;
		padding: 5px 10px;
	}

	.product-title {
		font-size: ($h3 * 0.85);
		line-height: 1.1;
	}

	.calories {
		font-size: $h5;
	}

	@media (min-width: 1370px) {
		@include grid(20%, true, 4px);
		// &:nth-child(5n + 5) {
		// 	margin-right: 0;
		// }
		// &:nth-child(5n + 6) {
		// 	clear: left;
		// }
	}

	@media (min-width: 876px) and (max-width: 1369px) {
		@include grid(3, true, 4px);
	}


	@media (max-width: 875px) and (min-width: 561px) {
		@include grid(4, true, 4px);

	}
	@media (max-width: 560px) {
		@include grid(6, true, 4px);
	}

	// @media (max-width: 420px) {
	// 	@include grid(12);
	//
	// }
}

.shop-create-img {
	display: block;
}

.message-wrapper {
	margin-bottom: $space3;
	text-align: center;
}

.message {
	margin-bottom: $space2;
	padding: $space1;
	&.error, &.invalid {
		color: red;
	}
	&.success {
		color: green;
	}
}

.choices {
	float: right;
}

.create-arrow {
	display: inline-block;
	float: right;
	height: 22px;
	margin-left: 10px;
	width: 30px;
	svg {
		display: block;
		height: auto;
		transform: rotate(90deg);
		width: 100%;
		height: 22px;
	}
	path {
		fill: inherit;
	}
	.section-open & {
		svg {
			transform: rotate(-90deg);
		}
	}
	@include s {
		width: 20px;
		height: 14px;
		svg {
			height: 16px;
		}
	};
}

.go-to-bag {
	border: 3px solid $black;
	font-weight: bold;
	padding: $space1 $space2;
	text-transform: uppercase;
}
