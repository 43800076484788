.contact-title {
	color: #72588c;
	font-weight: 500;
	&:after {
		background-image: url("public/img/plus-purple.png");
	}
}
.contact-content {
	color: #008778;
	padding-top: 0;
	padding-bottom: 1rem;
	max-width: 90vw;
	@media (max-width: #{$m - 1}) {
		padding-left: 0;
		padding-right: 0;
		max-width: 75vw;
	}
}
.contact-label {
	display: block;
	font-weight: 500;
}
.contact-form {
	width: 400px;
	max-width: 90%;
	textarea {
		display: block;
		background: #fbfaf7;
		border: none;
		width: 100%;
		min-height: 10em;
		max-height: 10em;
		margin-bottom: 1rem;
		padding: .5em;
	}
	input[type="email"] {
	    display: block;
	    background: #fbfaf7;
	    border: none;
	    width: 100%;
	    padding: .5em;
	}
	.contact-label {
	    margin-top: 1rem;
	}
	button, input[type=submit] {
		&:hover {
			background: #008778;
		}
	}
	.message {
		margin-bottom: 0;
		padding: 0;
	}
}
.contact-footer {
	color: #ed7248;
	width: 16rem;
	position: relative;
	padding: 1rem;
	font-weight: bold;
	font-size: .9rem;
	@media (min-width: 340px) {
		border: 5px solid;
		&:after {
			content: "";
			position: absolute;
			width: 43px;
			height: 43px;
			background: url("public/img/plus-orange.png") no-repeat center;
			top: -24px;
			right: -24px;
		}
	}
	@media (min-width: $m) {
		margin-top: -5px;
		margin-left: -5.2rem;
	}
	@media (min-width: $l) {
		margin-left: -15.2rem;
	} 
}

.screen-reader-response {
	display: none;
}

.wpcf7-response-output {
	text-align: center;
	margin-top: $space2;
}
