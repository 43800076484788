.page-legals {
	background: #58595b;
	color: white;
}
.legals-page {
	@extend .center-wrapper;
	margin-top: 0;
	padding-top: 2rem;
	position: relative;
}
.legals-menu {
	background: #58595b;
}
