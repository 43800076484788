
@mixin centerX {
	left: 50%;
	@include transform(translateX(-50%));
}
@mixin centerY {
	top: 50%;
	@include transform(translateY(-50%));
}
@mixin centerXY {
	left: 50%;
	top: 50%;
	@include transform(translate(-50%, -50%));
}

@mixin centerXYand($args...) {
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%) $args;
}

@mixin normalize {
	top: auto;
	left: auto;
	transform: none;
}

@mixin iblock { display: inline-block }
@mixin block { display: block }
@mixin hide { display: none }
@mixin flex {
	@supports (display: flex) {
		display: flex
	}
}
@mixin flexRow {
	@supports (display: flex) {
		display: flex; flex-direction: row
	}
}
@mixin flexCol {
	@supports (display: flex) {
		display: flex; flex-direction: column
	}
}
@mixin flexCenter {
	@supports (display: flex) {
		display: flex; align-items: center; justify-content: center 
	}
}

@mixin vmiddle { vertical-align: middle; }

@mixin absolute { position: absolute }
@mixin relative { position: relative }
@mixin fixed { position: fixed }

@mixin clear { clear: both }
@mixin floatLeft { float: left }
@mixin floatRight { float: right }
@mixin noFloat { float: none }

@mixin pinLeft { left: 0 }
@mixin pinRight { right: 0 }
@mixin pinTop { top: 0 }
@mixin pinBottom { bottom: 0 }
@mixin pinTopLeft { top: 0; left: 0 }
@mixin pinTopRight { top: 0; right: 0 }
@mixin pinBottomLeft { bottom: 0; left: 0 }
@mixin pinBottomRight { bottom: 0; right: 0 }
@mixin zn1 { z-index: -1 }
@mixin z0 { z-index: 0 }
@mixin z1 { z-index: 1 }
@mixin z2 { z-index: 2 }
@mixin z3 { z-index: 3 }
@mixin z4 { z-index: 4 }

%centerX{ @include centerX }
%centerY{ @include centerY }
%centerXY{ @include centerXY }

%iblock { @include iblock }
%block { @include block }
%hide { @include hide }
%flex { @include flex }
%flexRow { @include flexRow }
%flexCol { @include flexCol }
%flexCenter { @include flexCenter }

%vmiddle { @include vmiddle;}

%absolute { @include absolute }
%relative { @include relative }
%fixed { @include fixed }

%clear { @include clear }
%floatLeft { @include floatLeft }
%floatRight { @include floatRight }
%noFloat { @include noFloat }

%pinLeft { @include pinLeft }
%pinRight { @include pinRight }
%pinTop { @include pinTop }
%pinBottom { @include pinBottom }
%pinTopLeft { @include pinTopLeft }
%pinTopRight { @include pinTopRight }
%pinBottomLeft { @include pinBottomLeft }
%pinBottomRight { @include pinBottomRight }
%zn1 { @include zn1 }
%z0 { @include z0 }
%z1 { @include z1 }
%z2 { @include z2 }
%z3 { @include z3 }
%z4 { @include z4 }

@include all(centerX) { @include centerX }
@include all(centerY) { @include centerY }
@include all(centerXY) { @include centerXY }

@include all(iblock) { @include iblock }
@include all(block) { @include block }
@include all(hide) { @include hide }
@include all(flex) { @include flex }
@include all(flexRow) { @include flexRow }
@include all(flexCol) { @include flexCol }
@include all(flexCenter) { @include flexCenter }

@include all(absolute) { @include absolute }
@include all(relative) { @include relative }
@include all(fixed) { @include fixed }

@include all(clear) { @include clear }
@include all(floatLeft) { @include floatLeft }
@include all(floatRight) { @include floatRight }
@include all(noFloat) { @include noFloat }

@include all(pinLeft) { @include pinLeft }
@include all(pinRight) { @include pinRight }
@include all(pinTop) { @include pinTop }
@include all(pinBottom) { @include pinBottom }
@include all(pinTopLeft) { @include pinTopLeft }
@include all(pinTopRight) { @include pinTopRight }
@include all(pinBottomLeft) { @include pinBottomLeft }
@include all(pinBottomRight) { @include pinBottomRight }
@include all(zn1) { @include zn1 }
@include all(z0) { @include z0 }
@include all(z1) { @include z1 }
@include all(z2) { @include z2 }
@include all(z3) { @include z3 }
@include all(z4) { @include z4 }
