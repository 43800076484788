$black : #585858;
$footerHeight : 32px;
$footerImageHeight: 145px;
$headerHeight : 80px;
$headerHeightMobile : 40px;
$headerColor : #f7f8f3;
// $headerColor : #F2EEE4;
$maxWidthLarge: 1280px;
$maxWidthSmall: 550px;
$subMenuHeight: 42px;
$subMenuHeightMobile: 126px;

@mixin largeTitle {
	text-transform: uppercase;
	@include m {
		font-size: $h1;
	};
}

@mixin entrySpacing {
	@include mx-auto;
	max-width: 1800px;
	padding: $space3 $space4;
	@include s {
		padding: $space2;
	};
}

@mixin border {
	border-width: 3px;
	border-style: solid;
}

.restrict {
	@include mx-auto;
	max-width: $maxWidthLarge;
}

.restrict-small {
	@include mx-auto;
	max-width: $maxWidthSmall;
}

.restrict-large {
	@include entrySpacing;
	@include group;
}


html {
	width: 100%;
	height: 100%;
	color: $black;
}

body {
	@extend %font-body;
	@include anim(opacity);
	background-color: #fff;
	font-size: 12px;
	font-size: 1.1rem;
	line-height: 1.4;
	min-height: 100%;
	// opacity: 0;
	overflow-x: hidden;
	width: 100%;
	overflow-y: scroll;
	// &.loaded {
	// 	opacity: 1;
	// }
}

main {
	position: relative;
	min-height: 800px;
	min-height: 100vh;
}

img {
	height: auto;
}
h1 {
	@extend %font-rounded;
}

.footer-banner {
	bottom: $footerHeight;
	left: 0;
	position: absolute;
	z-index: 2;
	@include lmax {
		bottom: 0;
	};
}

.os-bg {
	height: 145px;
	width: 100%;
	background-repeat: repeat-x;
	background-position: center top;
}

.bg-beige {
	background-image: url(public/img/bg-beige.png);
}

.bg-img {
	@include fill;
	@include pinTopRight;
	background-attachment: fixed;
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
}

.entry-wrapper {
	padding-top: $headerHeight;
	padding-bottom: $footerHeight;
	position: relative;

	.has-footer-banner & {
		padding-bottom: $footerHeight + $footerImageHeight + 20px;
	}
	.entry {

		min-height: 600px;
		min-height: calc(100vh - #{$headerHeight + $footerHeight});
		padding-top: $subMenuHeight;
		position: relative;
	}
	@include lmax {
		padding-top: $headerHeightMobile;
		.entry {
			.show-sub-menu & {
				padding-top: $subMenuHeightMobile;
			}
		}
	};
}

.entry-header {
	position: relative;
	.entry-title {
		// TODO: sort out the font feature
		-moz-font-feature-settings:"ss04=1";
		-moz-font-feature-settings:"ss04";
		-ms-font-feature-settings:"ss04";
		-webkit-font-feature-settings:"ss04";
		font-feature-settings:"ss04";
		text-rendering: optimizeLegibility;
		// font-feature-settings:"calt", "liga", "clig", "kern";
		@include entrySpacing;
	}
}

.header-content {
	font-family: $font-body;
	font-size: $h4;
	font-weight: normal;
	letter-spacing: 0.5px;
	margin-top: $space2;
	text-transform: none;
	p {
		margin-bottom: $space2;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.entry-title {
	font-family: $font-rounded;
	@include largeTitle;
}

.product-title {
	font-size: $h3;
	font-weight: 500;
	text-transform: lowercase;
	@include s {
		font-size: inherit;
	};
}

.product-ingredient {
	font-size: $h4;
	text-transform: lowercase;
}

.entry-content {
	@include s {
		padding: 0 $space2;
	};
	p {
		margin-bottom: $space2;
		@include s {
			font-size: $h6;
		};
	}
}

.featured-img {
	@include pinTopLeft;
	~ .entry-title {
		position: absolute;
		top: $subMenuHeight;
		// .show-sub-menu & {
		// 	top: 0;
		// }
	}
}

.slider {
	@include group;
}

.page-wrapper {
	margin: $space3 $space4;
	max-width: 1200px;
	min-height: calc(100vh - 120px);
	@media (min-width: $m) {
		min-height: calc(100vh - 320px);
	}
	@include s {
		padding: $space2;
		margin: 0;
	};
}

.center-wrapper {
	margin: 1rem auto 2rem;
	max-width: 1400px;
	min-height: calc(100vh - 120px);
	@media (min-width: $m) {
		// margin-top: 3rem;
		width: 90%;
		min-height: calc(100vh - 320px);
	}
}

.page-title {
	text-transform: uppercase;
	margin-bottom: 1rem;
	@media (min-width: $m) {
		font-size: 32px;
	}
}

.responsive-image {
	position: relative;
	width: 100%;
	img {
		height: 100%;
		object-fit: cover;
		position: absolute;
		width: 100%;
	}
}

.entry-bg {
	background-color: #fff;
	display: block;
	width: 100%;
}

.page-background {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: -1;
	background: white;
	transition: background .25s .25s;
}

.shop-message {
	text-align: center;
	padding: $space2;
	position: relative;
	@include s {
		font-size: $h5;
	};
}
