.form-page, .join-page, .account-login {
	@extend .center-wrapper;
	max-width: 500px;
	display: flex;
	align-items: center;
	width: 100%;
}

.form-title, .join-title {
	color: #ffce64;
	font-family: inherit;
	font-size: $h2;
	font-weight: 500;
	text-transform: lowercase;
	@media (max-width: #{340px - 1}) {
		padding: 1rem;
	}
	@media (min-width: 340px) {
		font-size: $h1;
		background: white;
		display: inline-block;
		padding: 0 .5em;
		border: 5px solid;
		outline: 10px solid white;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			width: 43px;
			height: 43px;
			background: url("public/img/plus.png") no-repeat center;
			bottom: -19px;
			right: -23.5px;
		}
	}
	@media (max-width: #{$m - 1}) and (min-width: 340px) {
		margin-left: 1rem;
	}
	@media (min-width: $m) {
		font-size: 54px;
		transform: translate(-120px, 5px);
	}
}
.form-content, .join-content {
	color: #ed7248;
	font-size: $h5;
	border: 5px solid;
	padding: $space2;
	font-weight: 500;
	margin-left: .5rem;
	margin-right: .5rem;
	@media (max-width: #{$m - 1}) and (min-width: 340px) {
		margin-left: 4rem;
		margin-right: 1rem;
	}
	@media (max-width: #{$m - 1}) {
		padding: 1rem;
	}
}
.form-row, .join-row {
	margin: 2rem auto;
	display: flex;
	> input {
		flex: 1;
	}
}
.form, .join-form {
	margin: 2rem auto .5rem;
	input[type="text"], input[type="password"] {
		border: none;
		border-bottom: 2px solid;
		vertical-align: bottom;
		width: 100%;
	}
	button, input[type=submit] {
		@extend %font-rounded;
		cursor: pointer;
		background: transparent;
		border: 2px solid;
		text-transform: uppercase;
		font-weight: bold;
		padding: .25em .5em;
		&:hover {
			background: #ed7248;
			color: white;
		}
	}
}
.form-label, .join-label {
	text-transform: lowercase;
	min-width: 50px;
	display: inline-block;
}
.form-button-box, .join-button-box {
	text-align: center;
	.message {
		font-size: $h6;
		&.success {
			color: green;
		}
		&.error {
			color: red;
		}
	}
}
