$nav: 1150px;
$m: 790px !default;
$l: 1150px !default;

$space1: .5rem !default;
$space2: 1rem !default;
$space3: 2rem !default;
$space4: 4rem !default;
$space5: 5rem !default;

$h1 : 2.5rem !default;
$h2 : 1.5rem !default;
$h3 : 1.25rem !default;
$h4 : 1rem !default;
$h5 : .875rem !default;
$h6 : .75rem !default;

@import "mixins";
@import "fonts";
@import "grid";
@import "tools";
@import "positioning";
@import "sizing";
@import "spacing";
@import "typography";
