$columns: 12;
$gutter: $space2;

@function grid-width($cols, $has-gutter:false, $gutter : $space2) {
 $expression : "(100% * #{$cols} / #{$columns})";
 @if $has-gutter == true {
   $expression: "(100% * #{$cols} / #{$columns}) - (#{$gutter} * (1 - #{$cols} / #{$columns}))";
 }

 @if str-index(quote($cols), %) != null {
	$expression : "#{$cols}";
	@if $has-gutter == true {
		$expression : "#{$cols} - #{$gutter}";
	}
 }

 @return calc(#{$expression});
}

@mixin group {
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}

@mixin grid($cols, $hasGutter : true, $thisGutter: $gutter) {
	$num : '';
	$percent : '';
	@if str-index(quote($cols), %) == null {
		$num : $columns / $cols;
		$percent : 100% / $num;
	}

	@if str-index(quote($cols), %) != null {
		$num : 100% / $cols;
		$percent : $cols;
	}

	float: left;
	width: $percent;
	width: grid-width($cols, $hasGutter, $thisGutter);
	@if ($hasGutter == true) {
		margin-right: $thisGutter;
	}
	&:nth-child(#{$num}n + #{$num}) {
		margin-right: 0;
	}
	&:nth-child(#{$num}n + #{$num + 1}) {
		clear: left;
	}
}

%group { @include group }
//%grid { @include grid }
%grid2 { @include grid(6); }
%grid3 { @include grid(4); }
//%grid3 { @include grid(3) }
%grid4 { @include grid(3) }
//%grid5 { @include grid(5) }
//%grid6 { @include grid(6) }
//%gutters1 { @include gutters($space1) }
//%gutters2 { @include gutters($space2) }
//%gutters3 { @include gutters($space3) }
//%gutters4 { @include gutters($space4) }
//
//@include all(grid) { @include grid }
@include all(grid2) { @include grid(6) }
@include all(grid3) { @include grid(4) }
//@include all(grid3) { @include grid(3) }
@include all(grid4) { @include grid(3) }
//@include all(grid5) { @include grid(5) }
//@include all(grid6) { @include grid(6) }
//@include all(gutters1) { @include gutters($space1) }
//@include all(gutters2) { @include gutters($space2) }
//@include all(gutters3) { @include gutters($space3) }
//@include all(gutters4) { @include gutters($space4) }
