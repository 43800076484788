
#main-header {
	background-color: $headerColor;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 4;
	@include l {
		body:not(.loaded) & {
			min-height: $headerHeight;
		}
	};
	@include lmax {
		height: $headerHeightMobile;
	};
	.mobile-bg {
		@include lmax {
			@include pinTopLeft;
			background-color: $headerColor;
			height: 100%;
			position: absolute;
			width: 100%;
			z-index: 4;
		};
	}
}
#main-nav {
	position: relative;
	transition: 0.5s opacity ease-out, transform 0.5s step-end;
	width: 100%;
	z-index: 5;
	@include l {
		background-color: #f7f8f3;
		.menu-item {
			@include py(0);
			line-height: $headerHeight;
		}
		.click-collect-wrapper {
			line-height: inherit;
			height: $headerHeight;
		}
		.click-item {
			line-height: $headerHeight / 2;
			line-height: $headerHeight;
			height: $headerHeight / 2;
			height: $headerHeight;
		}
	};

	@include lmax {
		background-color: #fff;
		height: 700px;
		height: calc(100vh - #{$headerHeightMobile});
		opacity: 0;
		padding-left: $space3;
		padding-top: $space3;
		top: $headerHeightMobile;
		transform: translateY(-110%);
		position: absolute;
		width: 100%;
		z-index: 4;
		.menu-open & {
			@include anim(opacity, 0.5s);
			opacity: 1;
			transform: translateY(0);
		}
	};

}

#sub-menu {
	@include anim(transform);
	background-color: #fff;
	left: 0;
	position: absolute;
	text-align: center;
	top: 100%;
	transform: translateY(-100%);
	width: 100%;
	max-width: 100vw;
	z-index: 4;
	.menu-open & {
		opacity: 0;
		z-index: -1;
	}
	.show-food & {
		transform: translateY(0);
	}
	@include lmax {
		z-index: 3;
	};

}

.sub-menu-link {
	display: inline-block;
	font-size: $h5 * 1.05;
	padding: 0 $space2;
	@include m {
		height: 42px;
		> * {
			vertical-align: middle;
		}
		// @supports (display: inline-flex) {
		// 	display: inline-flex;
		// 	justify-content: center;
		// 	align-items: center;
		// }
	};
	.link-text {
		display: inline-block;
		font-weight: normal;
		letter-spacing: 0;
		text-transform: none;
		@media (max-width: 350px) {
			font-size: $h6;
		}
	}
	&:hover, &.current {
		opacity: 1;
		.img-bg {
			opacity: 1;
		}
		path {
			fill: $headerColor;
		}
	}
	@include s {
		@include grid(6, false);
		text-align: left;
		white-space: nowrap;
	};
	@media (max-width: 350px) {
		padding: 0 $space1;
	}
}

.menu-img {
	display: inline-block;
	margin-right: 5px;
	padding: 11px;
	position: relative;
	svg {
		display: block;
		position: relative;
		height: 20px;
		width: 20px;
	}
	.link-drinks & {
		svg {
			width: 25px;
		}
	}
	path {
		@include anim;
	}
	@include s {
		min-width: 47px;
		text-align: center;
		svg {
			display: inline;
			height: 10px;
			transform: scale(1.6);
			width: 10px;
		}
	};
	@media (max-width: 350px) {
		min-width: inherit;
		padding: 5px;
		width: 25px;
		svg {
			transform: scale(1.2);
		}
	};
}

.img-bg {
	@include anim;
	@include fill;
	@include pinTopLeft;
	opacity: 0;
	position: absolute;
}

.site-logo {
	position: absolute;
	width: 130px;
	z-index: 6;
	svg {
		display: block;
		height: auto;
		width: 100%;
	}
	@include centerXY;
	@include lmax {
		width: 60px;
	};
}

.burger {
	@include px(10px);
	height: 100%;
	position: relative;
	width: 45px;
	z-index: 4;
	.bar {
		@include anim(all, 0.5s);
		background-color: $black;
		display: block;
		height: 1px;
		margin-bottom: 5px;
		width: 100%;
		&:last-child {
			margin-bottom: 0;
		}
		.menu-open & {
			&:first-child {
				transform: rotate(45deg) translate(5px,3px);
			}
			&:nth-child(2) {
				opacity: 0;
			}
			&:last-child {
				transform: rotate(-45deg) translate(5px, -4px);
			}
		}
	}
	@include l {
		display: none;
	};
	@include lmax {
		@include flexCenter;
		flex-direction: column;
	};
}

$logoWidth: 150px;

.nav-items {
	@include l {
		@include mx-auto;
		width: 72%;
		text-align: center;
		&.items-6 {
			.item-wrapper {
				width: (100% / 6) - 3%;
				width: calc(100% / 6 - #{$logoWidth / 6});
				&:nth-child(3) {
					margin-right: ($logoWidth / 2);
				}
				&:nth-child(4) {
					margin-left: ($logoWidth / 2);
				}
			}
		}
		&.items-5 {
			@include l {
				transform: translateX(-#{$logoWidth / 1.8});
				width: 900px;
			};
			@media (min-width: 1500px) {
				transform: translateX(-#{$logoWidth / 1.5});
				width: 1100px;
			}
			.item-wrapper {
				width: (100% / 5) - 4%;
				&:nth-child(3) {
					margin-right: ($logoWidth / 2);
				}
				&:nth-child(4) {
					margin-left: ($logoWidth / 2);
				}
			}
		}
		&.items-4 {
			.item-wrapper {
				width: (100% / 4) - 3%;
				width: calc(100% / 4 - #{$logoWidth / 2});
				&:nth-child(2) {
					margin-right: ($logoWidth / 2);
				}
				&:nth-child(3) {
					margin-left: ($logoWidth / 2);
				}
			}
		}
		.menu-item {
			display: block;

		}

	};
}

.item-wrapper {
	display: block;
	@include l {
		display: inline-block;
	};
}

.menu-item {
	@extend %font-rounded;
	display: inline-block;
	font-size: $h6 * 0.9;
	padding: $space1 $space2;
	white-space: nowrap;
	@include l {
		padding: $space3 0;
	};
}

.link {
	font-weight: bold;
	text-transform: uppercase;
	letter-spacing: 1.5px;
}

.click-collect-wrapper {
	background-color: $black;
	@include l {
		@include pinTopRight;
		position: absolute;
	};
	@include lmax {
		display: inline-block;
	};
}

.click-collect {
	@extend .menu-item;
	color: #fff;
	@include lmax {
		background-color: $black;
		margin-bottom: $space1;
	};
	@include l {
		@include px($space2);
	};
}

.header-shop-links {
	z-index: 6;
	a {
		display: inline-block;
		padding: 5px 10px;
		width: 50%;
		text-align: center;
		@supports (display: inline-flex) {
			align-items: center;
			display: inline-flex;
			justify-content: center;
			text-align: left;
			width: auto;
		}
	}
	svg {
		display: inline-block;
		width: 13px;
		height: 20px;
		path {
			fill: #fff;
		}
	}


	@supports (display: inline-flex) {
		display: flex;
		align-items: center;
		justify-content: space-around;
		flex-direction: row;
	}
	@include lmax {
		width: 146px;
	};
}

.shop-link {
	position: relative;
	&:last-child {
		svg {
			width: 17px;
		}
	}
}

.cart-notify {
	color: #fff;
	height: 20px;
	display: none;
	font-weight: 500;
	opacity: 0;
	transform: scale3d(0.8, 0.8, 1);
	transition: 0.3s opacity ease-in-out, 0.5s transform cubic-bezier(0.47, 0.6, 0, 1);
	width: 20px;
	height: 20px;
	.number {
		display: block;
		font-size: $h6;
	}
	&.show {
		// animation-name: buzz-in;
		// animation-duration: 0.75s;
		// animation-timing-function: linear;
		// animation-iteration-count: 1;
		@include flexCenter;
		opacity: 1;
		transform: scale3d(1,1,1);
	}
	&.go {
		animation-name: buzz;
		animation-duration: 0.75s;
		animation-timing-function: linear;
		animation-iteration-count: 1;
	}
}


$steps : (
	(3px, 2deg, 0.6, 0),
	(-3px, -2deg, 0.7, 0.1),
	(3px, 2deg, 0.8, 0.3),
	(-3px, -2deg, 0.9, 0.5),
	(2px, 1deg, 1, 0.7),
	(-2px, -1deg, 1, 1),
	(2px, 1deg, 1, 1),
	(-2px, -1deg, 1, 1),
	(1px, 0, 1, 1),
	(-1px, 0, 1, 1)
);

@keyframes buzz-in {
	@for $i from 1 through length($steps) {
		$step : nth($steps, $i);
		#{$i * 10%} {
			transform: translateX(nth($step, 1)) rotate(nth($step, 2)) scale(nth($step, 3));
			opacity: nth($step, 4);
		}
	}
}
@keyframes buzz {
	@for $i from 1 through length($steps) {
		$step : nth($steps, $i);
		#{$i * 10%} {
			transform: translateX(nth($step, 1)) rotate(nth($step, 2));
		}
	}
}
