$transitionDuration: .5s;
.entry-wrapper {
	// transition: padding $transitionDuration*.5;
	transition: none;
	opacity: 0;
	.loaded & {
		// transition: 0.5s ease-in;
		opacity: 1;
		animation: pageIn 0.5s ease-in;
		// animation-delay: 0.3s;
		z-index: 1;
	}
	&.page-cart {
		opacity: 1;
		&.loaded {
			animation: none;
		}
	}

}
.no-animate {
	opacity: 1;
}
// @keyframes pageOut {
// 	0% { opacity: 1; transform: none }
// 	50% { opacity: 0; transform: scale(1.1) }
// 	100% { opacity: 0 }
// }
// @keyframes pageIn {
// 	0% { opacity: 0 }
// 	50% { opacity: 0; transform: scale(.9) }
// 	100% { opacity: 1; transform: none }
// }
@keyframes pageOut {
	0% { opacity: 1; }
	100% { opacity: 0 }
}
@keyframes pageIn {
	0% { opacity: 0 }
	100% { opacity: 1; }
}
