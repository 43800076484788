
@mixin mx ($s) { margin-left: $s; margin-right: $s }
@mixin my ($s) { margin-top: $s; margin-bottom: $s }
@mixin px ($s) { padding-left: $s; padding-right: $s }
@mixin py ($s) { padding-top: $s; padding-bottom: $s }
@mixin mx-auto { margin-left: auto; margin-right: auto; }


%m0 { margin: 0 auto }
%p0 { padding: 0 }
@each $n, $space in (0, 0), (1, $space1), (2, $space2), (3, $space3), (4, $space4), (5, $space5) {
	%m#{$n} { margin: $space }
	%mt#{$n}, %my#{$n} { margin-top: $space }
	%mb#{$n}, %my#{$n} { margin-bottom: $space }
	%ml#{$n}, %mx#{$n} { margin-left: $space }
	%mr#{$n}, %mx#{$n} { margin-right: $space }
	%mxn#{$n} { margin-right: -$space; margin-left: -$space }
	%p#{$n} { padding: $space }
	%pt#{$n} { padding-top: $space }
	%pr#{$n} { padding-right: $space }
	%pb#{$n} { padding-bottom: $space }
	%pl#{$n} { padding-left: $space }
	%py#{$n} { padding-top: $space; padding-bottom: $space }
	%px#{$n} { padding-left: $space; padding-right: $space }
}
%mx-auto { @include mx-auto; }
@each $n, $space in (0, 0), (1, $space1), (2, $space2), (3, $space3), (4, $space4), (5, $space5) {
	@include all(m#{$n}) { margin: $space };
	@include all(mt#{$n}) { margin-top: $space };
	@include all(mb#{$n}) { margin-bottom: $space };
	@include all(ml#{$n}) { margin-left: $space };
	@include all(mr#{$n}) { margin-right: $space };
	@include all(mxn#{$n}) { margin-right: -$space; margin-left: -$space };
	@include all(p#{$n}) { padding: $space };
	@include all(pt#{$n}) { padding-top: $space };
	@include all(pr#{$n}) { padding-right: $space };
	@include all(pl#{$n}) { padding-left: $space };
	@include all(pb#{$n}) { padding-bottom: $space };
	@include all(py#{$n}) { padding-top: $space; padding-bottom: $space };
	@include all(px#{$n}) { padding-left: $space; padding-right: $space };
}
@include all(mx-auto) { margin-left: auto; margin-right: auto; };

// Allow for dynamic creation of margin and padding extenders/mixins

// store autogenerated selectors that map
//$Margin-Selectors: ();
//
//
//@mixin m($dir, $val: 20px, $extend: true, $media: null, $param: max-width) {
//	$selector: map-get($Margin-Selectors, m#{$dir}-#{$val});
//	@if $media != null {
//		$selector: map-get($Margin-Selectors, m#{$dir}-#{$val}-#{$media}-#{$param});
//	}
//
//	@if $extend == true {
//		@if $selector == null {
//			// create a unique placeholder selector
//			$selector: unique-id();
//			@if $media != null {
//				$Margin-Selectors: map-merge($Margin-Selectors, (m#{$dir}-#{$val}-#{$media}-#{$param}: $selector)) !global;
//			} @else {
//				$Margin-Selectors: map-merge($Margin-Selectors, (m#{$dir}-#{$val}: $selector)) !global;
//			}
//
//
//			// add a placeholder class that includes this mixin properties
//			@at-root %#{$selector} {
//				@if $media != null {
//					@media ($param : $media) {
//						@include m($dir, $val, false, null);
//					}
//				} @else {
//					@include m($dir, $val, false, null);
//				}
//
//			}
//		}
//
//		// default behavior is to extend the placeholder
//		@extend %#{$selector};
//	}
//	@else {
//		$dirs : ((t, margin-top), (r, margin-right), (b, margin-bottom), (l, margin-left));
//		@each $dirLetter, $value in $dirs {
//			@if $dir == $dirLetter {
//				@if $media != null {
//					@media ($param : $media) {
//						#{$value} : $val
//					}
//				} @else {
//					#{$value} : $val
//				}
//
//			}
//		}
//
//
//
//	}
//}
// Allow mixins that share common properties in a single css rule

// store autogenerated selectors that map
//$Padding-Selectors: ();
//
//
//@mixin p($dir, $val, $extend: true, $media: null, $param: max-width) {
//	$selector: map-get($Padding-Selectors, p#{$dir}-#{$val});
//	@if $media != null {
//		$selector: map-get($Padding-Selectors, p#{$dir}-#{$val}-#{$media}-#{$param});
//	}
//
//	@if $extend == true {
//		@if $selector == null {
//			// create a unique placeholder selector
//			$selector: unique-id();
//			@if $media != null {
//				$Padding-Selectors: map-merge($Padding-Selectors, (p#{$dir}-#{$val}-#{$media}-#{$param}: $selector)) !global;
//			} @else {
//				$Padding-Selectors: map-merge($Padding-Selectors, (p#{$dir}-#{$val}: $selector)) !global;
//			}
//
//
//			// add a placeholder class that includes this mixin properties
//			@at-root %#{$selector} {
//				@if $media != null {
//					@media ($param : $media) {
//						@include p($dir, $val, false, null);
//					}
//				} @else {
//					@include p($dir, $val, false, null);
//				}
//
//			}
//		}
//
//		// default behavior is to extend the placeholder
//		@extend %#{$selector};
//	}
//	@else {
//		$dirs : ((t, padding-top), (r, padding-right), (b, padding-bottom), (l, padding-left));
//		@each $dirLetter, $value in $dirs {
//			@if $dir == $dirLetter {
//				@if $media != null {
//					@media ($param : $media) {
//						#{$value} : $val
//					}
//				} @else {
//					#{$value} : $val
//				}
//
//			}
//		}
//	}
//}
