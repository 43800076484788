.page-thanks {
	.entry-header {
		margin-top: -$subMenuHeight;
		height: 600px;
		height: calc(100vh - #{$headerHeight});
		overflow: hidden;
		position: absolute;
		z-index: 1;
		img {
			@include fill;
			object-fit: cover;
			@include s {
				opacity: 0;
			};
		}
	}
	.entry-title {
		h2 {
			font-size: $h3;
		}
	}
}

.thanks-message {
	span {
		font-weight: bold;
	}
}
