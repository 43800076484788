.page-meet-the-team {
	color: #f2eee4;
	&:not(.ng-leave) ~ .page-background {
		background: #72588c;
	}
}
.team-page {
	padding: 0 1rem;
	@extend .center-wrapper;
}
.team-title {
	@extend .page-title;
}
.team-grid {
	display: flex;
	flex-wrap: wrap;
	max-width: 1200px;
	margin: 1rem auto 2rem;
	@media (min-width: $m) {
		justify-content: center;
	}
}
.team-item {
	font-size: $h6;
	width: 100%;
	padding: 0 .5rem;
	margin-bottom: 2rem;
	@media (min-width: 300px) {
		width: 33.33%;
	}
	@media (min-width: $m) {
		font-size: $h5;
		padding: 0 2rem;
		margin-bottom: 4rem;
	}
}
.team-name {
	margin-top: 1rem;
}
