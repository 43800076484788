#main-footer {
	// @extend %font-geo;
	@include anim(opacity, 0.5s);
	position: fixed;
	bottom: 0;
	z-index: 6;
	width: 100%;
	@include l {
		background-color: $headerColor;
		text-align: center;
	};
	@include lmax {
		opacity: 0;
		padding-left: $space3;
		top: 370px;
		z-index: -1;
		.menu-open & {
			opacity: 1;
			z-index: 6;
		}
	};
	.social-icons {
		@include l {
			@include pinTopRight;
			padding-right: $space2;
			position: absolute;
		};
	}
	.social-icon {
		@include l {
			padding: $space1;
			width: 32px;
			&:hover {
				transform: scale(1.2);
			}
		};
	}
}

.footer-menu-link {
	display: inline-block;
	font-size: $h6;
	font-weight: normal;
	@include l {
		padding: $space1 $space2;
	};
	@include lmax {
		display: block;
		margin-bottom: $space2;
		padding-left: $space2;
	};
}
