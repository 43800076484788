.section-page {
	@extend .center-wrapper;
	margin-top: 0;
	padding-top: 2rem;
	position: relative;
}
.section-menu {
	@include flexCenter;
	position: fixed;
	left: 0;
	top: $headerHeight;
	text-align: center;
	list-style: none;
	margin-bottom: 4rem;
	padding: 1rem 0 1rem;
	width: 100%;
	z-index: 1;
	@include lmax {
		top: $headerHeightMobile;
	};
	li {
		@media (min-width: $m) {
			display: inline-block;
			&:not(:last-child) {
				margin-right: 4rem;
			}
		}
	}
	a {
		display: inline-block;
		padding: .25em .5em;
		text-transform: lowercase;
		&.active {
			outline: 2px solid;
		}
	}
	@media (min-width: $l) {
		// padding-left: 40%;
		text-align: left;
	}
	@media (max-width: #{$m - 1}) {
		display: none;
	}
}
.section {
	margin-bottom: 4rem;
	position: relative;
	&:before {
		content: "";
		position: absolute;
		top: -4rem;
		width: 1px;
		height: 1px;
	}
	@media (min-width: $l) {
		display: flex;
		margin-bottom: 8rem;
	}
}

.section-title {
	@extend .page-title;
	.title {
		padding: 0;
	}
	@media (min-width: $m) {
		font-size: 42px;
	}
	@media (min-width: $l) {
		width: 40%;
	}
	@include s {
		padding: 0 $space1;
	};

}
.section-text {
	font-size: $h5;
	img {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}
	p {
		margin-bottom: $space2;
	}
	a {
		text-decoration: underline;
	}
	@media (min-width: $l) {
		width: 60%;
		max-width: 500px;
		margin-top: 5rem;
	}
	@include s {
		padding: 0 $space2
	};
}
.section-submenu {
	font-size: 1rem;
	list-style: none;
	padding: 0 1rem;
	a {
		cursor: pointer;
	}
	a:not(.active) {
		opacity: .5;
	}
}
