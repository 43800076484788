.create-category {
	// @include grid(6, true, 60px);
	@include border;
	background-color: #fff;
	break-inside: avoid-column;
	display: inline-block;
	margin-bottom: $space3;
	@include lmax {
		@include mx-auto;
	};
}

.categories-wrapper {
	@include group;
	padding: $space3;
	position: relative;
	@include l {
		@supports (column-count: 2) {
			display: column;
			column-count: 2;
			column-gap: 70px
		}
	};
	@include s {
		padding: $space2;
	};
}

.cat-title {
	@extend %font-rounded;
	border-bottom-style: solid;
	border-bottom-width: 3px;
	font-size: $h3;
	padding: ($space1 * 0.6) $space1;
	text-transform: uppercase;
	width: 100%;
}

.cat-product-title {
	color: $black;
	display: inline-block;
	font-weight: 500;
	padding: ($space1 * 0.3) $space1;
	text-transform: lowercase;
	width: 49%;
	&.meta-title {
		padding: 0;
		width: 100%;
	}
}

.create-meta-wrapper {
	@extend .products-wrapper;
	margin-bottom: 20px;
	.price {
		color: rgb(105, 191, 172);
	}
}
