.page-just-in {
	color: #f2eee4;
	background: #ed7248;
}
.just-in-menu {
	background: #ed7248;
}
.just-in-item {
	margin-bottom: 2rem;
	position: relative;
	padding-top: 1rem;
	@media (min-width: $m) {
		&:before {
			content: "+";
			position: absolute;
			left: -1rem;
			top: 0;
		}
	}
}
.just-in-title {
	margin-bottom: 1rem;
}
