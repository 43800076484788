
.signature-soups-products, .soup-menu-wrapper {
	max-width: 1000px;
}

.signature-soups-product {
	@include border;
	@include l {
		@include grid(6, true, $space4);
	};
	@include s {
		@include grid(12);
	};

	.ingredients-wrapper {
		margin-bottom: $space3;
	}
}

.small-grid-product {
	@include border;
	@include group;
	.shop.category-drinks & {
		border-top: none;
	}
	background-color: #fff;
	margin-bottom: $space3;
	overflow: hidden;
}

.product-content {
	// @include grid(77%, false);
	border-color: inherit;
	padding: $space1;
	padding-top: ($space1 * 0.1);
	.drinks-product & {
		line-height: 1;
		padding-top: $space1;
	}
	position: relative;

}

.suggested {
	font-size: $h4;
	margin-bottom: $space2;
}

.product-side-meta {
	@include grid(23%, false);
	padding: $space1;
	padding-top: ($space1 * 0.5);
	.drinks-product & {
		padding-top: $space1;
	}

}

.allergy {
	width: auto;
	height: 30px;
}
