.shop-sidebar {
	height: 100%;
	max-width: 400px;
	position: relative;
	width: 100%;
}

.current-order {
	@include group;
	margin-bottom: $space4;
	overflow-y: auto;
	@media (min-width: 1061px) {
		max-height: (50px * 8);
	}
	@media (max-width: 1060px) {
		margin-top: $space3;
		max-height: none !important;
	}
}

.current-order-item {
	float: left;
	margin-bottom: $space1;
	margin-right: $space2;
	.plus {
		font-size: $h2;
	}
	.item-title {
		@include border;
		border-color: inherit;
		color: inherit;
		display: inline-block;
		font-size: $h4;
		font-weight: bold;
		margin-left: ($space1 * 0.5);
		padding: ($space1 * 0.5) $space1;
		text-transform: lowercase;
	}
}

$cats : ('.current-cat-bases', '.current-cat-ingredients', '.current-cat-premiums', '.current-cat-toppings', '.current-cat-dressings', '.empty');
$len : length($cats);
@for $i from 1 to $len {
	#{unquote(nth($cats, $i))} + #{unquote(nth($cats, $i + 1))} {
		clear: left;
	}
	@for $n from $i + 1 to $len {
		#{unquote(nth($cats, $i))} + #{unquote(nth($cats, $n))} {
			clear: left;
		}
	}
}



.create-form {
	border: 3px solid $headerColor;
	width: 100%;
	input {
		@include cleanInput;
	}
	.submit-btn {
		border-top: 3px solid $headerColor;
	}
}

.create-form-label {
	@include group;
	@include px(5px);
	display: table;
	margin-bottom: $space1;
	width: 100%;
	> * {
		display: table-cell;
		width: 50%;
		vertical-align: middle;
	}
	> *:first-child {
		margin-right: $space4;
	}
	> *:last-child {
		text-align: right;
		> * {
			vertical-align: middle;
		}
	}

}


.quantity-input {
	@include noSelect;
	path {
		fill: $black;
	}
}

#create-quantity {
	text-align: center;
	width: 70px;
}

.quantity-arrow {
	cursor: pointer;
	display: inline-block;
	height: 27px;
	width: 20px;
	svg {
		display: block;
		height: 20px;
		margin-top: 4px;
		width: 20px;
	}
	&.less {
		svg {
			transform: rotate(180deg);
		}
	}
	&.more {
		margin-left: -12px;
	}
}

.choice-text {
	cursor: pointer;
	&.yes {
		margin-right: $space3;
	}
	input:checked ~ & {
		font-weight: bold;
	}
}

.cost-value {
	input {
		max-width: 40px;
		text-align: right;
	}
}

#create-name {
	background-color: transparent;
	border: none;
	border-bottom: 3px solid $black;
	display: inline-block;
	outline: none;
	text-align: right;
	width: 100%;
}

label[for=order-name] {
	display: table;
	> * {
		display: table-cell;
	}
	> span:first-child {
		width: 10%;
		min-width: 50px;
	}
}

#create-cals {
	width: 100%;
}

.submit-btn {
	@include anim;
	background-color: $black;
	color: #fff;
	cursor: pointer;
	font-size: $h4;
	padding: ($space1 / 2) $space1;
	text-transform: uppercase;
	width: 100%;
	&:hover {
		background-color: $headerColor;
		color: $black;
	}
}

.cart-wrapper {
	background-color: #fff;
	font-size: $h4;
	width: 100%;
	.reaching & {
		@media (min-width: 1061px) {
			bottom: 0;
			position: absolute;
		}
	}
	@media (max-width: 1060px) {
		margin-top: $space3;
	}
}
