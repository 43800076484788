
@mixin filter($s...) {
	-webkit-filter: $s;
	filter: $s;
}
@mixin transform($s...) {
	-ms-transform: $s;
	-webkit-transform: $s;
	transform: $s;
}

$globalEase : cubic-bezier(.25,.8,.25,1);

@mixin anim($prop: all, $time : 0.4s, $ease : cubic-bezier(.25,.8,.25,1)) {
	transition: $prop $time $ease;
}

@mixin shadow {
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

@mixin xs { @media (max-width: (550px)) { @content } };
@mixin xsmin { @media (min-width: (551px)) { @content } };
@mixin s { @media (max-width: $m) { @content } };
@mixin m { @media (min-width: $m + 1) { @content } };
@mixin l { @media (min-width: $l + 1px) { @content } };
@mixin lmax { @media (max-width: $l) { @content } };
@mixin all($class) {
	@include xs { %xs-#{$class} { @content } };
	@include xsmin { %xsmin-#{$class} { @content } };
	@include s { %s-#{$class} { @content } };
	@include m { %m-#{$class} { @content } };
	@include l { %l-#{$class} { @content } };
	@include lmax { %lmax-#{$class} { @content } };
};

@mixin placeholder {
	&::-webkit-input-placeholder { @content }
	&::-moz-placeholder { @content }
	&:-ms-input-placeholder { @content }
}
@mixin prefix($name, $arg) {
	-webkit-#{$name}: #{$arg};
	-ms-#{$name}: #{$arg};
	-moz-#{$name}: #{$arg};
	#{$name}: #{$arg};
}
