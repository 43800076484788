.page-our-menu {
	.entry-title {
		color: #fff;
	}
	.entry-header {
		height: 700px;
		height: calc(100vh - #{$headerHeight + $subMenuHeight + $footerHeight});
		img {
			@include fill;
			object-fit: cover;
		}
	}
}





.grid-product {
	@include grid(3, true, $space3);
	margin-bottom: $space3;
	text-transform: lowercase;
	img {
		width: 100%;
	}
	@media (max-width: 950px) {
		@include grid(6, true, $space3);
	};

	@media (max-width: 550px) {
		@include grid(12);
	}
}

.ingredients-wrapper {
	line-height: 1.2;
	margin-bottom: $space2;
}


@import 'menu-parts/create';
@import 'menu-parts/salads';
@import 'menu-parts/wraps';
@import 'menu-parts/soups';
@import 'menu-parts/breakfast';
@import 'menu-parts/drinks';

.products-wrapper {
	@include group;
	@include mx-auto;
	padding: $space1 $space4;
	@include s {
		padding: $space1 $space2;
	};
}
