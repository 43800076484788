@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin font($name, $folder, $file, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
	$src: null;

	$extmods: (
		eot: "?",
		svg: "#" + str-replace($name, " ", "_")
	);

	$formats: (
		otf: "opentype",
		ttf: "truetype"
	);

	@each $ext in $exts {
		$extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append($src, url(quote("public/fonts/webfonts/" + $folder + "/" + $file + "." + $extmod)) format(quote($format)), comma);
	}

	@font-face {
		font-family: quote($name);
		font-style: $style;
		font-weight: $weight;
		src: $src,
        local($name),
        local($folder);
	}
}

// $font-body : 'PT Mono', monospace;

$fonts : (
	("MaaxGeometric", "MaaxGeometricReg", "maaxgeometricregular", normal),
	("MaaxGeometric", "MaaxGeometricBold", "maaxgeometricbold", 700),
	("MaaxGeometric", "MaaxGeometricMedium", "maaxgeometricmedium", 500),
	("MaaxRounded", "MaaxRoundedBold-Web", "maaxroundedbold", 700, null, ('ttf')),
	("MaaxStandard", "MaaxStandardReg", "maaxstandardregular", normal),
	("MaaxStandard", "MaaxStandardBold", "maaxstandardbold", 700),
	("MaaxStandard", "MaaxStandardMedium", "maaxstandardmedium", 500)
);

@each $font in $fonts {
	@include font(nth($font, 1), nth($font, 2), nth($font, 3), nth($font, 4));
}



.title {
	@extend %s-h3,
	%m-h2;
	font-weight: bold;
	letter-spacing: 1px;
	text-transform: uppercase;
	&.page-title {
		@extend %center;
		font-size: 4rem;
		line-height: 1.2;
		@include s {
			font-size: 1.8rem;
		};
	}
}


.btn, .link {
	text-transform: lowercase;
	&:hover {
		opacity: 0.6;
	}
}


$font-body : 'MaaxStandard', sans-serif;
$font-geo : 'MaaxGeometric', sans-serif;
$font-rounded : 'MaaxRounded', sans-serif;


%font-body {
    font-family: $font-body;
}

%font-geo {
	font-family: $font-geo;
}

%font-rounded {
	font-family: $font-rounded;
}
