.location-page {
	@extend .page-wrapper;

	@media (max-width: #{$m - 1}) {
		p {
			margin-bottom: 0;
		}
	}
}
.location-additional {
    padding-top: 4rem;
}
.location-content {
	color: $black;
	width: 40em;
	h1 {
		@extend .page-title;
	}
	@media (max-width: 300px) {
		flex-wrap: wrap;
	}
	@media (max-width: #{$m - 1}) {
		display: flex;
		justify-content: flex-start;
		width: 100%;
	}
	@media (min-width: $m) {
		max-width: 30%;
	}
}
.location-column {
	width: 10rem;
	@media (min-width: $m) {
		width: 100%;
		max-width: 75%;
	}
}
.location-link {
	display: inline-block;
	text-decoration: underline;
	margin-top: 2rem;
	@media (min-width: $m) {
		margin-bottom: 5rem;
		margin-top: 1rem;
	}
	@media (max-width: 300px) {
		margin-top: 0;
		margin-bottom: .5rem;
	}
}
.location-image {
	width: 100%;
	height: 300px;
	background-repeat: no-repeat;
	background-position: center;
    background-size: contain;
    display: none;
	@media (min-width: $m) {
        display: block;
		// @include centerXY;
		height: 500px;
        margin-left: auto;
        margin-top: -400px;
		width: 500px;
	}
}
.location-times {
	@media (max-width: #{$m - 1}) {
		margin-top: 2.35rem;
	}
	@media (max-width: 300px) {
		margin-top: 0;
	}
}
