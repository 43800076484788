.outdated-popup {
	@include pinTopLeft;
	background-color: $headerColor;
	display: none;
	padding: $space2;
	position: fixed;
	text-align: center;
	width: 100%;
	z-index: 9999;
	&.show {
		display: block;
	}
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}
