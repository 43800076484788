
@each $n, $size in ((1 $h1) (2 $h2) (3 $h3) (4 $h4) (5 $h5) (6 $h6)) {
	%h#{$n} { font-size: $size }
	@include all(h#{$n}) { font-size: $size }
}


@mixin white { color: white }
@mixin black { color: black }

@mixin upper { text-transform: uppercase }
@mixin lower { text-transform: uppercase }
@mixin noCase { text-transform: none }

@mixin left { text-align: left }
@mixin right { text-align: right }
@mixin center { text-align: center }
@mixin justify { text-align: justify }

@mixin italic { font-style: italic }
@mixin regular { font-style: normal }
@mixin light { font-weight: 300 }
@mixin normal { font-weight: normal }
@mixin bold { font-weight: bold }

%white { @include white }
%black { @include black }

%upper { @include upper }
%lower { @include lower }
%noCase { @include noCase }

%left { @include left }
%right { @include right }
%center { @include center }
%justify { @include justify }

%italic { @include italic }
%regular { @include regular }
%light { @include light }
%normal { @include normal }
%bold { @include bold }

@include all(white) { @include white }
@include all(black) { @include black }

@include all(upper) { @include upper }
@include all(lower) { @include lower }
@include all(noCase) { @include noCase }

@include all(left) { @include left }
@include all(right) { @include right }
@include all(center) { @include center }
@include all(justify) { @include justify }


@include all(italic) { @include italic }
@include all(regular) { @include regular }
@include all(light) { @include light }
@include all(normal) { @include normal }
@include all(bold) { @include bold }
