$spacing : 15%;

@import "parts/slider";

.home-entry {
	// .slider-wrapper {
	// 	// margin-top: -$subMenuHeight;
	// }
}

.home-article {
	@include entrySpacing;
	.entry-title {
		margin-bottom: $space2;
	}
}

.farmdrop {
	@include group;
	margin-top: $space3;
	@media (min-width: 910px) {
		width: 60%;
		max-width: 100%;
		min-width: 900px;
		margin-left: 0;
		.title {
			font-size: $h2;
			margin-bottom: $space3;
		}
	};
}

.farmdrop-text {
	float: left;
	max-width: 400px;
	a {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.farmdrop-img {
	float: right;
}
