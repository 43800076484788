

.shop-products-wrapper {
	@include mx-auto;
	@include group;
	max-width: 1800px;
	padding: $space3 $space4 $space3;
	.product {
		cursor: pointer;
	}
	@include s {
		padding: $space2;
	};
}

.archive-products-wrapper, .archive-small-grid {
	.product {
		.marker {
			// margin-top: $space2;
		}
		.marker.tick {
			margin-top: 0;
		}
	}
}

.small-grid-product {
	position: relative;
}

.product-img-wrapper {
	margin-bottom: $space2;
	position: relative;
	img {
		display: block;
	}

}



.add-text {
	@include anim(opacity);
	bottom: $space1;
	color: #fff;
	position: absolute;
	text-align: center;
	width: 100%;
	.loading &,.added &  {
		opacity: 0;
	}
}

.product-overlay {
	@include anim(opacity);
	@include fill;
	@include pinTopLeft;
	opacity: 0;
	position: absolute;
	z-index: 2;
	.marker {
		@include anim();
		@include centerXY;
		position: absolute;
		width: 70px;
		height: 70px;
		opacity: 0;
		padding: 20px 20px 20px 20px;
		@include centerXYand(scale(0.6));
		&.tick {
			// background-color: fade-out(green, 0.3);
			border-radius: 100%;
			// margin-top: -20px;
			// position: relative;
			.bg {
				@include centerXY;
				border-radius: 100%;
				width: 70px;
				height: 70px;
			}
			svg {
				@include centerXY;
				position: absolute;
				width: 30px;
			}
		}
		&.load {
			@include flexCenter;
			padding: 0;
			@include centerXYand(scale(1));
		}
		&:active {
			@include centerXYand(scale(0.6));
		}
	}
	svg {
		width: 100%;
		height: 30px;
	}
	.bg {
		@include anim();
		@include centerXY;
		@include fill;
		opacity: 0.6;
		position: absolute;
	}
	.product:hover & {
		opacity: 1;
		.marker {
			&.add {
				opacity: 1;
				@include centerXYand(scale(1));
			}
		}
	}
	.added & {
		opacity: 1;
		> .bg:not(.drinks-grid-bg) {
			background-color: transparent !important;
		}
		.marker {
			&.add {
				opacity: 0 !important;
				@include centerXYand(scale(0) !important);
			}
			&.tick {
				opacity: 1;
				@include centerXYand(scale(1));
			}
		}
	}
	.loading & {
		.marker {
			&.add, &.tick {
				opacity: 0 !important;
			}
			&.load {
				opacity: 1;

			}
		}
	}
}

.archive-small-grid {
	.product {
		cursor: pointer;
	}
	.overlay {
		height: 104%;
		margin-top: -1%;
		> .bg {
			opacity: 1;
		}
	}
}

.added-text {
	@include anim(opacity);
	opacity: 0;
	.added & {
		opacity: 1;
	}
}




@import "create";
@import "drinks";
